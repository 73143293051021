/**
 * Gatsby:AppRegistry
 * Config static props for site metadata
 */
const AppRegistry = {
    BaseUrl: "https://solder.ai",
    Social: {
        Email: 'hey@solder.ai',
        Phone: '1-800-SOLDER1',
        Linkedin: 'https://linkedin.com/company/SolderAI',
        Github: 'https://github.com/SolderAI',
        DevTo: 'https://dev.to/@SolderAI',
        Twitter: 'https://twitter.com/SolderAI',
        TwitterHandle: '@SolderAI'
    },
    Copyright: {
        Full: "© Solder.AI | All Rights Reserved.",
    },
    Tokens: {
        GoogleTags: [
            ''
        ]
    }
}

export default AppRegistry;
