import * as React from 'react';
import { Flex, Box, Text } from '@chakra-ui/react';
import Logo from '../../components/Design/Logo';
import { PrimaryLogo } from '@SolderAI/library';
import { Metadata } from '../../seo/Metadata';

class Placeholder extends React.Component {
    public render() {
        return (
            <Flex
                flexWrap={'wrap'}
                alignItems={'center'}
                sx={{
                    width: '100vw',
                    height: '100vh',
                    mx: 'auto',
                    textAlign: 'center',
                    flexDirection: 'column',
                    alignItems: 'center',
                    alignContent: 'center',
                    padding: [5, 7, 9],
                }}
            >
                <Box maxW={'512px'}>
                    <PrimaryLogo />
                </Box>
                <Text>{Metadata.description}</Text>
            </Flex>
        );
    }
}

export default Placeholder;
