import AppRegistry from "../static/AppRegistry";

/**
 * Gatsby:Site:Metadata
 */
export const MetadataDev = {
    title: "SOLDER | Low-Code AI Models with GraphQL API | Train, Test, Deploy",
    short: "Low-Code No-Code AI at your fingertips",
    description:
        "Build and launch production ready models in minutes with a GraphQL API backend and high performance inference.",
    author: `@SolderAI`,
    siteUrl: AppRegistry.BaseUrl,
};

export const Metadata = {
    title: 'Edge Enabled. Join us to build the future.',
    short: 'Edge Enabled. Join us to build the future.',
    description: "Hey! We're launching when its ready. Curious? Check back soon to help shape the future.",
    author: `SolderAI`,
    siteUrl: AppRegistry.BaseUrl,
}
