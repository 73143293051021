import { extendTheme } from '@chakra-ui/react';

import fonts from './fonts';
import colors from './colors';
import animate from './animate';
import breakpoints from './breakpoints';

const theme = extendTheme({
    config: {
        initialColorMode: 'dark',
        useSystemColorMode: false,
    },
    styles: {
        global: {
            body: {
                bg: '#000000',
            },
        },
    },
    colors: {
        brand: {
            ...colors,
        },
    },
    fonts: { ...fonts },
    antimate: { ...animate },
    breakpoints: { ...breakpoints },
});

export default theme;
