import React from 'react';

import AppContainer from '../components/Layout/AppContainer';
import Layout from '../components/Layout/Layout';

import CoverContent from '../content/containers/CoverContent';
import Home from '../content/pages/Home';
import Placeholder from '../content/pages/Placeholder';
import { Metadata } from '../seo/Metadata';
import SEO from '../seo/SEO';

/**
 * Declare default resource path adapter for {AppContainer} passthrough
 * @returns {JSX.Element}
 * @constructor
 */
class Index extends React.Component {
    public render() {
        return (
            <AppContainer>
                <SEO title={Metadata.title} />
                <Placeholder />
            </AppContainer>
        );
    }
}

export default Index;

// <Layout cover={<CoverContent />}>
//     <Home />
// </Layout>;
