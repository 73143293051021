import * as React from 'react';
import { ChakraProvider, ColorModeScript } from '@chakra-ui/react';
import theme from '../../theme/theme';

import './app.css';

/**
 * AppContainer default resource
 */
class AppContainer extends React.Component<{}> {
    public render() {
        return (
            <ChakraProvider theme={theme}>
                <ColorModeScript
                    initialColorMode={theme.config.initialColorMode}
                />
                {this.props.children}
            </ChakraProvider>
        );
    }
}

export default AppContainer;
