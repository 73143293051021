/**
 * @constant colors
 */
const colors = {
    white: '#FFFFFF',
    black: '#000000',
    alpha: {
      black: {
        alpha5: '#0005',
        alpha7: '#0007',
        alpha9: '#0009',
      },
    },
    accent: {
        red: '#EB5757',
        green: '#27AE60',
        blue: '#2D9CDB',
        yellow: '#E8D457',
        slate: '#1D293F',
    },
    greys: {
        white5: '#FEFEFE',
        white10: '#FAFAFA',
        grey70: '#565656',
        black90: '#232323',
        black95: '#121212',
    },
};

export default colors;
