/**
 * @constant fonts
 */
const fonts = {
  heading: 'Open Sans, sans-serif',
  body: 'Red Hat Display, sans-serif',
  monospace: 'monospace',
};

export default fonts;
